export default defineStore('AlertBoxStore', {
    state: () => ({
        content: {
            text: '',
            isError: false,
        },
        isOpenValue: false,
    }),

    actions: {
        async toggleAlertbox(forceClose = false) {
            this.isOpenValue = forceClose ? false : !this.isOpenValue;

            const { sleep } = useHelpers();
            await sleep(6500);
            this.isOpenValue = false;
        },

        setAlertBoxData(type, data) {
            const settings = { ...data };
            const typeIsError = type === 'error';

            this.content.text = typeIsError ? settings.error_text : settings.success_text;
            this.content.isError = typeIsError;
        },
    },

    getters: {
        isOpen(state) {
            return state.isOpenValue;
        },
        alertBoxContent(state) {
            return state.content;
        },
    },
});
